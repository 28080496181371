/*
 * 업무구분 : 고객
 * 화 면 명 : MSPCM047M
 * 화면설명 : 고객등록완료
 * 작 성 일 : 2022.11.28
 * 작 성 자 : sh.park2022
 */
<template>
  <ur-page-container class="msp btn-back-clear" title="고객등록완료" :show-title="true" type="subpage" :topButton="true" :action-type="actionSlot">
    <!-- Content 영역 ::start -->
    <ur-box-container direction="column" alignV="start" class="msp-cm-wrap">
      <ur-box-container alignV="start" componentid="" direction="column" class="ns-info-area">

        <ur-box-container alignV="start" componentid="" direction="column" class="info-box pt20 pb20">
          <h4 class="con-title1">관리자 승인 후 <br />고객동의가 완료됩니다.</h4>
          <p class="con-title4 crTy-bk7 mt8 mb0">승인 전 등록된 고객정보는 <strong>'미승인고객'</strong>에서<br />확인할 수 있습니다.</p>
        </ur-box-container>

        <ur-box-container alignV="start" componentid="" direction="column" class="info-box">
          <ur-box-container alignV="start" componentid="" direction="column" class="ns-contract-list type-1">
            <mo-list :list-data="custCardVOList">
              <template #list-item="{item}">
                <mo-list-item>
                  <div class="list-item__contents">
                    <div class="list-item__contents__title display-inline-flex">
                      <mo-badge v-if="item.mnCustRltnCd === '01'" class="badge-sample-badge lightblue sm" shape="status">
                        본인
                      </mo-badge>
                      <mo-badge v-else-if="item.mnCustRltnCd === '03'" class="badge-sample-badge lightblue sm" shape="status">
                        자녀
                      </mo-badge>
                      <mo-badge v-else-if="item.mnCustRltnCd === '04'" class="badge-sample-badge lightblue sm" shape="status">
                        손자
                      </mo-badge>
                      <mo-badge v-else class="badge-sample-badge lightblue sm" shape="status">
                        기타
                      </mo-badge>
                      <span class="name txtSkip ml10">{{ item.custNm }}</span>
                    </div>
                    <div class="list-item__contents__info">
                      <div class="txt_box">
                        <!-- 생년월일 -->
                        <span>{{ $bizUtil.dateDashFormat(item.dob) }}</span><em>|</em>
                        <!-- 성별 -->
                        <span>{{ item.knbBkFstNo%2 === 1 ? '남성' : '여성' }}</span><em>|</em>
                        <!-- 나이 -->
                        <span>{{ item.age }}세</span>
                      </div>
                    </div>
                  </div>
                </mo-list-item>
              </template>
            </mo-list> 
          </ur-box-container>

        </ur-box-container>

        <ur-box-container alignV="start" componentid="" direction="column" class="ns-btn-relative">
          <div class="relative-div">
            <mo-button componentid="" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_MovePage('add')">고객추가등록</mo-button>
            <mo-button componentid="" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_MovePage('done')">완료</mo-button>

          </div>
        </ur-box-container>

      </ur-box-container>
    </ur-box-container>
  </ur-page-container>
</template>

<script>
/************************************************************************************************
 * 공통 라이브러리 INCLUDE 영역
************************************************************************************************/
import Msg from '@/systems/webkit/msg/msg'

import MSPCM048P from '@/ui/cm/MSPCM048P' // 승인요청 발송

export default {
  /************************************************************************************************
   * Vue 파일 속성 정의 영역
  ************************************************************************************************/
  // 현재 화면명
  name: 'MSPCM047M',
  // 현재 화면 ID
  screenId: 'MSPCM047M',

  /************************************************************************************************
   * 화면 전역 변수 선언 영역
  ************************************************************************************************/
  data () {
    return {
      title: '고객등록 완료', // 화면명
      lv_UserInfo: {},

      actionSlot:{}, // 동의서 발행 (상단 버튼 Slot)
      custCardVOList: []
    }
  },
  /************************************************************************************************
   * Life Cycle 함수 정의 영역                                                                    *
  ************************************************************************************************/
  created() {
    this.lv_UserInfo = this.getStore('userInfo').getters.getUserInfo

    // backKey event 등록
    window.vue.getWkEvtHandler().$on(Msg.DEVICE.BACK_KEY, this.fn_MovePage)
  },
  mounted() {
    const lv_Vm = this

    // 화면접속로그 등록(필수)
    this.$bizUtil.insSrnLog('MSPCM047M')

    let tempCont = ''
    if (['FC', 'TC'].includes(this.lv_UserInfo.chnlScCd)) { // FC채널 사용자가 아니라면 비활성화(2023.06.14 황고은 프로)
      tempCont = '<span><mo-button style="margin-right: 13px" shape="border" class="ns-btn-category" @click="fn_ClickEvent()">승인요청 발송</mo-button></span>'
    } else {
      tempCont = '<span><mo-button style="margin-right: 13px;background: #ccc !important;color:#fff !important;" shape="border" class="ns-btn-category" disabled>승인요청 발송</mo-button></span>'
    }


    // 상단 검색, 카테고리 버튼 생성
    this.actionSlot = {
      actionComponent: {
        template: tempCont,
          methods: {
            fn_ClickEvent() {
              // MSPCM048P 호출
              lv_Vm.fn_OpenPop('MSPCM048P')
            },
          },
        },
    }

    this.fn_Init()
  },
  beforeDestroy() {
    // backKey event 해제
    window.vue.getWkEvtHandler().$off(Msg.DEVICE.BACK_KEY, this.fn_MovePage)
  },
  /************************************************************************************************
   * 사용자 함수 정의 영역
  ************************************************************************************************/
  methods: {
    /*********************************************************
     * @function fn_Init
     * @notice   화면진입시, 최초 실행 함수
    *********************************************************/
    fn_Init () {
      const lv_Vm = this
      
      if (!_.isEmpty(this.$route.params.pCustCardVO)) {
        this.custCardVOList = this.$route.params.pCustCardVO

        this.custCardVOList.forEach(item => {
            let itemKnb = item.knb.substr(0,7)
            item.age = item.knbBackup ? lv_Vm.$bizUtil.getAgeFromKnb(itemKnb) : ''

            if (this.$bizUtil.isEmpty(item.dob)) {
              item.dob = !this.$bizUtil.isEmpty(itemKnb) ? this.$cmUtil.getFullBirthDate(itemKnb) : ''
            } else {
              item.dob = item.dob.substr(0,8)
            }

            if (this.$bizUtil.isEmpty(item.knbBkFstNo)) {
              item.knbBkFstNo = item.knbBkno.substr(0,1)
            }
          });

      } else {
        // 메인홈으로 전환
        this.$router.push({ name: 'MSPBC002M' })
      }
    },
    /*********************************************************
     * @function fn_MovePage
     * @notice   추가등록/완료 버튼 Click Evnet
    *********************************************************/
    fn_MovePage (param) {
      let pageNm = ''
      let preSrnObj = window.vue.getStore('cmStore').getters.getState.preSrnObjCM050M
      let pageCnt = window.vue.getStore('cmStore').getters.getState.viewCntCM050M

      if (param === 'add') {
        pageNm = 'MSPCM040M' // 지류-고객등록
        this.$router.go(-(pageCnt))
        return
      } else {
        window.vue.getStore('cmStore').dispatch('PRE_SRN_OBJ_CM050M', {}) // 진입전 정보 초기화

        if (_.isEmpty(preSrnObj.rtnParam)) {
          this.$router.push({ name: 'MSPCM220M' }) // 미승인 고객 조회
        } else { // 진입 전 화면
          this.$router.push({ name: preSrnObj.srnId, params: preSrnObj.rtnParam })
        }
      }
    },
    /******************************************************************************
     * @function fn_OpenPop
     * @name     팝업_호출
     * @notice   승인요청 팝업(MSPCM048P)
     * @param    srnId: Screen ID
    ******************************************************************************/
    fn_OpenPop (srnId) {
      const lv_Vm = this
      let srnComponent = '' // 오픈시킬 팝업
      let srnProp = {}      // 팝업 Props
      let listeners = {}    // emit Listeners

      if (srnId === 'MSPCM048P') {
        srnComponent = MSPCM048P
        srnProp = { pCustCardVOList: lv_Vm.custCardVOList } 
        listeners = {
          onPopupConfirm: () => {
            lv_Vm.$bottomModal.close(lv_Vm.popupObj)
          },
          onPopupCancel: () => {
            lv_Vm.$bottomModal.close(lv_Vm.popupObj)
          }
        };
      }

      // 팝업창 띄우기
      this.popupObj = lv_Vm.$bottomModal.open(srnComponent, {
        properties: srnProp,
        listeners: listeners
      });

    },
  } // ::Methods End
}
</script>
